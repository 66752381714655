import React from 'react';
import logo from './aegon-logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
         Welcome To Aegon Network.. <br /> Scalling Ethereum <b />Launching Soon! 
        </p>
        <a
          className="App-link"
          href="https://t.me/aegonnetwork"
          target="_blank"
          rel="noopener noreferrer"
        >
          Join Community...
        </a>      
      </header>
    </div>
  );
}

export default App;
